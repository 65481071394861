<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :save-params="{is_main : false}">

        <div class="row">
            <div class="col-12">
                <validated-input label="Title*" name="Title" v-model="model.title"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input type="number" label="Order" v-model="model.order"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>

            <div class="col-12 mb-2">
                <label>Description*</label>
                <ckeditor class="form-control" v-model="model.description"
                          :editor="editor" :config="editorConfig"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                     loading-text="Saving..." :disabled="loading"/>
            </div>
        </div>

    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';

export default {
    name       : 'AddTermsAndConditions',
    components : { ckeditor : CKEditor.component },

    data () {
        return {
            addUrl       : urls.cms.termsCondition.addEdit,
            editorConfig : {
                toolbar : {
                    items : [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'undo',
                        'redo'
                    ]
                },
                table : {
                    contentToolbar : ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                language : 'en'
            },
            editor : ClassicEditor
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Terms & Conditions..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
