<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>TERMS & CONDITIONS</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.termsAndConditionsAddModal.show()"
                     text="Add"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
        </div>

        <custom-vue-table :show-search-box="false" class="d-sm-none-lg-block" :fields="fields" :url="listUrl"
                          :per-page="10" ref="table" :extra-params="{ext_search}">

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>

        </custom-vue-table>
        <terms-and-conditions-mobile-view :ext_search="ext_search" ref="termsAndConditionsMobileView" @viewDetails="viewDetails" @setDelete="setDelete"
                                          class="d-lg-none"></terms-and-conditions-mobile-view>

        <modal title="Add Terms & Conditions" ref="termsAndConditionsAddModal" width="50r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddTermsAndConditions @success="formSuccess"></AddTermsAndConditions>
        </modal>

        <modal title="Edit Terms & Conditions" ref="termsAndConditionsEditModal" :no-close-on-backdrop="true"
               width="50r" header-color="primary">
            <EditTermsAndConditions :initial-data="editingItem" @success="formSuccess"></EditTermsAndConditions>
        </modal>

        <delete-modal ref="termsAndConditionsDeleteModal" :url="deleteUrl" :params="deletingItem"
                      @response="deleteComplete">
            <p>You are about to delete the Terms & Conditions <b
                v-html="deletingItem && deletingItem.conditions_document"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.conditions_document"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddTermsAndConditions from './AddTermsAndConditions';
import EditTermsAndConditions from './EditTermsAndConditions';
import TermsAndConditionsMobileView
from '../../../../views/cms/privacy-and-terms/terms-and-conditions/TermsAndConditionsMobileView';

export default {
    name : 'TermsAndConditions',

    components : { TermsAndConditionsMobileView, AddTermsAndConditions, EditTermsAndConditions },

    data () {
        return {
            ext_search : '',
            listUrl    : urls.cms.termsCondition.list,
            deleteUrl  : urls.cms.termsCondition.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'order',
                    sortField : 'order',
                    title     : 'Order'
                },
                {
                    name      : 'title',
                    sortField : 'title',
                    title     : 'Title'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewDetails (id) {
            this.$router.push('/cms/terms-and-conditions/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.termsAndConditionsAddModal.close();
            refs.termsAndConditionsEditModal.close();
            refs.termsAndConditionsMobileView.loadData();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.termsAndConditionsEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.termsAndConditionsDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted..!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.termsAndConditionsDeleteModal.close();
            this.$refs.termsAndConditionsMobileView.loadData();
            this.$refs.table.refreshTable();
        }

    }
};
</script>

<style scoped>

</style>
